<template>
    <div class="view">
        <div class="main">
            <div class="main-body">
                <div class="cephalo">
                    <div class="main-title">
                        <div class="main-title-img">
                           <img :src="ossUrl + 'lsj1.png'" alt="">
                        </div>
                        <div class="main-title-text">
                            乐搜家——家居供应链数智化平台，以技术驱动为核心“集产业+大数据+数字化+智能营销”推动产业发展。全面赋能B端客户精准匹配、高效成交、担保交易、及时交付、物流仓储、到家安装、售后保障等，给予更好的解决方案。为家具生产企业降本增效，提升市场竞争力，提高数字化应用能力，助力生产管理标准化。
                        </div>
                    </div>
                </div>
                <div class="detailed">
                    <div class="aboutLesou">
                        <div class="aboutLesou-left">
                            <div class="title">
                            乐搜家项目介绍
                            </div>
                            <div class="aboutLesou-text">
			        	    	<div class="aboutLesou-text-item">
			        	    		模式：家居Saas+F2B2C+数字化平台
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		系统集成：设计软件（酷家乐/三维家）—ERP—MES—SRM—CRM—TMS...
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		数字化：产品设计—订单管理—材料研发—材料采购—生产流程—交付状态—物流运输—送货安装—售后保养等全链路无纸化系统管理。
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		大数据：产业数据（企业数据、产能数据、产品数据），销售数据（专卖店、渠道商、电商、直播），排行榜（品牌、销售、口碑、价格、服务）
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		供应对象：设计机构、材料商、生产工厂、经销商、物流企业、安装师傅、企业服务商等
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		买家对象：软装设计师、家居经销商、家居电商、直播机构、地产商、渠道商、贸易商、出口跨境等 
			        	    	</div>
			        	    	<div class="aboutLesou-text-item">
			        	    		金融保险：供应链金融、产品保险、运输保险、保理业务等
			        	    	</div>
			        	    </div>
                        </div>
                        <div class="aboutLesou-right">
                            <img :src="ossUrl + 'lsj2.png'" alt="">
                        </div>
                    </div>
                    <div class="factory">
                        <div class="factory-left">
                            <img :src="ossUrl + 'lsj3.png'" alt="">
                        </div>
                        <div class="factory-right">
                            <div class="title">
                            家具工厂痛点
                            </div>
                            <div class="factory-text">
			        	    	<div class="factory-text-item">
			        	    		商品: 缺少高清图片、白底图、3D素材、案例等;
			        	    	</div>
			        	    	<div class="factory-text-item">
                                    品质: 生产过程，有心无力;行业竞争大，默认偷工减料;
			        	    	</div>
			        	    	<div class="factory-text-item">
                                    人员: 难招人、管理难、效率低、成本高、风险大;
			        	    	</div>
			        	    	<div class="factory-text-item">
                                    营销: 卖场租金高、流量少;人才难留，庸才无效益; 
			        	    	</div>
			        	    	<div class="factory-text-item">
                                    时效: 有单做不出来，无单养不起人;先做催单的，先做有利润的
			        	    	</div>
                                <div class="factory-text-item">
                                    资金: 恶性三角债;研发投入大、用钱无规划;
			        	    	</div>
			        	    	<div class="factory-text-item">
                                    服务: 报价慢、回复慢、售后处理慢
			        	    	</div>
			        	    	<div class="factory-text-item">
			        	    		工具: 缺乏软件应用经验，缺少学习案例，实施困难
			        	    	</div>
                                <div class="factory-text-item">
                                    信息: 杂、乱、多，容易误导做出错误判断
			        	    	</div>
                                <div class="factory-text-item">
                                    物流: 延误、货损、丢失、没有标准、无保障;
			        	    	</div>
                                <div class="factory-text-item">
                                    进度: 微信催、电话问、现场看;多层传递
			        	    	</div>
			        	    </div>
                        </div>
                    </div>
                    <div class="design">
                        <div class="design-left">
                            <img :src="ossUrl + 'lsj4.png'" alt="">
                        </div>
                        <div class="design-right">
                            <div class="title">
                            装企、设计师痛点
                            </div>
                            <div class="design-text">
			        	    	<div class="design-text-item">
			        	    		素材: 白底图、3D模型等素材稀缺
			        	    	</div>
			        	    	<div class="design-text-item">
                                    选品: 有素材设计出效果图，匹配产品、厂家难度大
			        	    	</div>
			        	    	<div class="design-text-item">
                                    品质: 产品外观大同小异，材料、工艺天差地别，不好评估质量等级
			        	    	</div>
			        	    	<div class="design-text-item">
                                    价格: 议价权低;议价困难、参差不齐、怕吃亏还耗时间
			        	    	</div>
			        	    	<div class="design-text-item">
                                    服务: 态度各式各样，回复及时性不稳定，沟通成本高
			        	    	</div>
                                <div class="design-text-item">
                                    交付: 准时交付比例不高，品质无忧的交付就更低，履约空间大
			        	    	</div>
			        	    	<div class="design-text-item">
                                    保障: 无理由退款?几乎没有。即使退款，也造成了很大时间成本
			        	    	</div>
			        	    	<div class="design-text-item">
			        	    		售后: 积极响应度较低;产品质量、物流货损等经常推脱责任
			        	    	</div>
                                <div class="design-text-item">
                                    进度: 微信催、电话问、现场看;多层传递
			        	    	</div>
			        	    </div>
                        </div>
                    </div>
                </div>
                <div class="timelineIntroduce">
                    <div class="backgroundInfo">
                        <div class="title">
                            背景介绍
                        </div>
                        <div class="backgroundInfo-text">
                            乐从先生团队2011年3月创建物流信息平台（爱物流、乐从物流网），已经运营十多年，为乐从本地家具企业、家具电商等提供物流信息查询、配送安装、售后处理等服务，在本地积累了较好口碑。在解决物流难题的同时，发现家具行业存在诸多痛点，有非常大的提升空间，在酝酿多年后决定开发家具平台，为行业发展赋能、提供更好解决方案。
                        </div>
                    </div>
                    <div class="timeline">
                        <div class="timeline-left">
                            <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20211-03
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        乐从物流网PC端上线运营
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                            <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20211-12
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        总计2000多家本地物流加入乐从物流网
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                            <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20217-01
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        “爱物流i56”小程序、App上线
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20218-06
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        成立家具平台项目筹备组
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20219-01
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        家居人之家新总部乔迁，位于佛山家博城C座
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        20219-06
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        乐从家具网测试版上线(求购+商城)
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        2020-01
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        入驻家具企业、材料商3000多家
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        2020-12
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        开始“乐搜家”新平台开发规划
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        2021-01
                                    </div>
                                </div>
                                <div class="box-content">
                                    <div class="content-text">
                                        进行系统重构(数字化+saas+商城+名片+物流）
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                             <div class="timeline-box">
                                <div class="box-title">
                                    <div class="box-title-img">
                                        <img :src="ossUrl + 'success-filling.png'" alt="">
                                    </div>
                                    <div class="box-title-time">
                                        2022-01
                                    </div>
                                </div>
                                <div class="box-content last-box">
                                    <div class="content-text">
                                        乐搜家平台正式上线 —— 未来可期
                                    </div>
                                    <div class="triangle"></div>
                                </div>
                            </div>
                        </div>
                        <div class="timeline-right">
                            <img :src="ossUrl + 'lsj5.png'" alt="">
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div class="decoration">
                        <img :src="ossUrl + 'lsj6.png'" alt="">
                    </div>
                    <div class="ContactUs">
                        <div class="title">
                            联系我们
                        </div>
                        <div class="ContactUs-text">
                            <div class="ContactUs-text-item">
                                乐搜家（广东）网络技术有限公司
                            </div>
                            <div class="ContactUs-text-item">
                                咨询电话：400-168-2356
                            </div>
                            <div class="ContactUs-text-item">
                                业务咨询：18942469630
                            </div>
                            <div class="ContactUs-text-item">
                                投诉监督：133 8050 4699
                            </div>
                            <div class="ContactUs-text-item">
                                地址：广东省佛山市禅城区家博城C座6楼6041-6050号
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import oucy from "@/util/oucyUtil.js"
export default {
    data(){
        return{
            ossUrl:oucy.serverImg + "lesoujia/PC/"
        }
    }
}
</script>

<style lang="less" scoped>
    .cephalo{
        padding: 0 12%;
        padding-top: 100px;
        padding-bottom: 35px;
        background: #fafafa;
    }
    .detailed{
        padding: 0 12%;
        padding-top: 35px;
        padding-bottom: 50px;
        background: #f6f7f9;
    }
    .timelineIntroduce{
        padding: 0 12%;
        padding-top: 35px;
        padding-bottom: 15px;
        background: #fafafa;
    }
    .contact{
        padding: 0 12%;
        padding-top: 50px;
        padding-bottom: 130px;
        background: #f6f7f9;
    }
    .last-box{
    		border-left: 0px solid #e7e7e7 !important;
    	}
    .title{
            display: inline-block;
	        padding: 0 2px;
	        height: 24px;
	        font-size: 20px;
	        font-weight: 700;
	        color: #333333;
	        background: linear-gradient(0deg, #cae7ff 10%, #fafafa00 35%);
	        margin-bottom: 1vw;
        }
    .view{
        background: #fafafa;
        padding-top:1%;

         .main{
            text-align: center;
            box-sizing: border-box;
            background: #fff;
            .main-body{
                    // margin: 0 12%;
                    // padding: 100px 0 150px;
                .main-title-img{
                    img{
                        width: 100%;
                    }
                }
                .main-title-text{
                    text-indent:2em;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 30px;
                    color: #555c68;
                    font-weight: 400;
                    margin-top: 90px;
                    text-align: left;
                }
                .aboutLesou{
                    margin-bottom: 100px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    .aboutLesou-left{
                            // display: inline-block;
                            text-align: left;
                            width: 61%;
                            height: 35%;
                        .aboutLesou-text{
                            font-size: 14px;
                            font-weight: 400;
                            color: #555c68;
                            line-height: 40px;
                            .aboutLesou-text-item{
                                line-height: 40px;
                            }
                        }
                    }
                    .aboutLesou-right{
                        text-align: right;
                        display: inline-block;
                        width: 35%;
                        img{
                            width: 100%;
                            max-height: 427px;
                            max-width: 427px;
                        }
                    }
                }
                .factory{
                    margin-bottom: 100px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    .factory-left{
                        text-align: left;
                        width: 61%;
                        margin-right: 3%;
                        img{
                            width: 100%;
                            max-height: 485px;
                            max-width: 886px;
                        }
                    }
                    .factory-right{
                            min-width: 424px;
                        .factory-text{
                            font-size: 14px;
                            font-weight: 400;
                            color: #555c68;
                            line-height: 40px;
                            .factory-text-item{
                                line-height: 40px;
                            }
                        }
                    }
                }
                .design{
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    .design-left{
                        text-align: left;
                        width: 61%;
                        margin-right: 3%;
                        img{
                            width: 100%;
                            max-height: 412px;
                            max-width: 736px;
                        }
                    }
                    .design-right{
                            min-width: 424px;
                        .design-text{
                            font-size: 14px;
                            font-weight: 400;
                            color: #555c68;
                            line-height: 40px;
                            .design-text-item{
                                line-height: 40px;
                            }
                        }
                    }
                }
                .backgroundInfo{
                    text-align: left;
                    margin-bottom: 50px;
                    .backgroundInfo-text{
                        height: 49px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #555c68;
                        line-height: 30px;
                        display: inline-block;
                    }
                }
                .timeline{
                    display: flex;
                    justify-content:space-between;
                    .timeline-left{
                        min-width: 400px;
                        .timeline-box{
                            margin-top: 3px;
                            .box-title{
                                display: flex;
                                img{
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 12px;
                                }
                                .box-title-time{
                                    height: 19px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #555c68;
                                    line-height: 19px;
                                }
                            }
                            .box-content{
                                position: relative;
                                border-left: 2px solid #e7e7e7;
                                height: 60px;
                                margin-left: 7px;
					            padding-left: 20px;
					            padding-top: 10px;
                                text-align: left;
                                .content-text{
                                    display: inline-block;
							        padding: 0 10px;
							        height: 35px;
							        font-size: 14px;
							        font-weight: 400;
							        text-align: center;
							        color: #555c68;
							        line-height: 35px;
							        background: #f6f7f8;
							        border-radius: 5px;
                                }
                                .triangle{
                                    width: 0;
						        	height: 0;
						        	border-left: 8px solid transparent;
						        	border-right: 8px solid transparent;
						        	border-bottom: 10px solid #f6f7f8;
						        	position: absolute;
						        	top: 3px;
                                    left: 30px;
                                }
                            }
                        }
                    }
                    .timeline-right{
                        display: flex;
                        align-items: center;
                        img{
                            max-width: 741px;
                            max-height: 403px;
                            width: 100%;
                        }
                    }
                }
                .decoration{
                    margin-bottom: 75px;
                    img{
                        width: 100%;
                    }
                }
                .ContactUs{
                    text-align: left;
                    .ContactUs-text{
                        height: 179px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #707070;
                        line-height: 40px;
                    }
                }
            }
        }
    }
   

</style>